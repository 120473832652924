<template>
<div class="tablediv">
  <h1>Раздел "Приходы благочиния"</h1>

  <h2 v-if="id > 0"><span v-html="this.prihodModel.name"/></h2>

  <PrihodMenu :selectedId="5" :prihodId="this.id"/>

    <table class="child tablestyling" cellspacing="0" cellpadding="3" v-if="this.loaded == true"> 
    <tr align="center" style="background-color:White;font-weight:bold;">
    <td><span class="header">ID</span></td>
    <td><span class="header">Название Альбома</span></td>
    <td><span class="header">Количество фоток</span></td>
    <td><span class="header">Вкл</span></td> 
    <td><span class="smalltext">Изменить</span></td>
    <td><span class="smalltext">Удалить</span></td>  
    </tr>

    <tr v-for="(albumItem, index) in prihodAlbumsModel.albums" v-bind:key="albumItem.id" :class="classForTrItem(index)">
    <td align="center">{{albumItem.id}}</td>
    <td align="center">{{albumItem.name}}</td>
    <td align="center">{{albumItem.quantityPhotos}}</td> 
    <td align="center">
    <span v-if="albumItem.visible == true" class="greentext">Да</span>
    <span v-if="albumItem.visible == false" class="redtext">Нет</span>
    </td>
    <td align="center">
    <router-link :to="{name:'editprihodalbum', params:{id : id, albumId : parseInt(albumItem.id)}}"><img src="../../assets/edit.png" border="0" alt="Редактировать"></router-link>
    </td>
    <td align="center">
      <img src="../../assets/delete_disabled.png" border="0" v-if="albumItem.quantityPhotos > 0" title="Сначала удалите все фото!">
      <img src="../../assets/delete.png" border="0" v-if="albumItem.quantityPhotos == 0" class="imgpointer" v-on:click="DeleteAlbum(albumItem.id)" alt="Удалить">
    </td>
    </tr>
    </table>
    <div class="likebr" style="float:right;" v-if="this.loaded == true">
    <b>Добавить альбом</b>:
    <router-link :to="{name:'editprihodalbum', params:{id:id, albumId:0}}"><img src="../../assets/add.png" border="0" alt="Добавить"></router-link>
    </div>   
</div>
</template>

<script>
import PrihodMenu from '../Prihods/PrihodMenu.vue';

export default 
{
name:'PrihodAlbumsList',
 components: {
    PrihodMenu
},
 props:
 {
     id: Number
 },
 data(){
    return {
      prihodModel: Object,
      prihodAlbumsModel: Object,
      loaded : Boolean
    }
  },
methods:{
    classForTrItem: function (trNumber) {
      return (trNumber + 1) % 2  == 0 ? 'maintr' : 'alternatetr';
    },

    async DeleteAlbum(albumId){
       try {
            this.$router.push({ name: 'deleteprihodalbum', params: { id: this.id, albumId: albumId,} });
      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
       try {
        this.loaded = false;
        
        if (this.id <= 0)
        {
            this.$router.push({ name: 'default'});
        }

        var responsePrihod = await this.$http.get(this.$restApi + '/prihods/' + this.id);
        this.prihodModel = responsePrihod.data;

        var response = await this.$http.get(this.$restApi + '/prihods/' + this.id + '/albums');
        this.prihodAlbumsModel = response.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
},

created(){
  this.getData();
 }
}
</script>

<style scoped>
.tablestyling{
border-color:#0066FF;
border-width:2px;
border-style:solid;
width:900px;
border-collapse:collapse;
margin-top: 20px;
}

.child{font-size: 13px; text-decoration: none;}
.child td {border:1px solid; border-bottom-color: #0066ff; border-top-color: #0066ff; border-right-color: #0066ff; border-left-color: #0066ff;}
.header{color:#000099}
.maintr{background-color:#FFFFE8;}
.alternatetr{background-color:#DDFFEF;}
</style>