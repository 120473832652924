<template>
<div>
    <div class="danger">Danger Zone</div>
    <table cellSpacing="4" align="center" border="0" style="padding-top:200px;">
    <tr>
    <td><div class="big_danger">Доступ запрещён!</div></td>
    </tr>
    </table>
</div>
</template>

<script>
export default 
{
name:'DeniedComponent',

components: {
},

props:{
  
},

data(){
    return {
        
    }
},

methods:{
    getData() {

    },

},

  created(){
  this.getData();
 },

   mounted(){
    this.$store.commit('formUpdate', {
            markupType: 2
        });
    }

}
</script>

<style scoped>
.danger{
    color:#af0606;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    padding-left:15px;
    padding-top:15px;
    font-weight: bold;
}

.big_danger{
    color:#af0606;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 30px;
    padding-left:15px;
    padding-top:15px;
    font-weight: bold;
    }
</style>