<template>
<div v-if="loaded">
    <h1>Раздел "Наши События"- Удаление</h1>
    <div class="redtext" v-if="inError == true">Не получилось удалить Событие. Свяжитесь с разработчиком.</div>

    <table cellspacing="1" cellpadding="3" border="0" class="child tablestyling" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
    <td align="center" class="bluehead bigtext" width="900">Вы действительно хотите удалить Событие c id = {{id}} и заголовком "{{head}}"?</td>
    </tr>
    <tr bgcolor="#ffffff">
    <td align="center">
    <button class="btn" v-on:click="deleteNews()">Да</button>&nbsp;&nbsp;
    <button class="btn" v-on:click="cancelDelete()">Нет</button>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
export default 
{
 name:'NewsDelete',
 props:
 {
   uid: String,
 },

 data(){
    return {
      inError: Boolean,
      loaded : Boolean,
      id: Number,
      head: String,
    }
  },
methods:{
        async getData() {
        try {
        var newsResponse = await this.$http.get(this.$restApi +'/news/' +  this.uid + '/anons');
        
        this.id = newsResponse.data.id; 
        this.head = newsResponse.data.header;
        this.inError = false;
        this.loaded = true;
        } catch (error) {
        console.log(error);
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'eventslist'});
        }
        }
    },

    async deleteNews()
  {
  try {
        await this.$http.delete(this.$restApi + '/news/' + this.uid + '/delete');   
        this.$router.push({ name: 'eventslist'});
      } catch (error) {
        this.inError = true;
        console.log(error);
      }
  },
  cancelDelete()
  {
    this.$router.push({ name: 'eventslist'});
  },
 },

 created(){
  this.getData();
},
}
</script>

<style scoped>
</style>