<template>
<div class="tablediv">
    <h1>Раздел "Приходы благочиния"</h1>
    <div v-if="loaded">
    <h2 v-if="id == 0">Добавление прихода</h2>
    <h2 v-if="id > 0"><span v-html="this.name"/></h2>

    <PrihodMenu :selectedId="1" :prihodId="this.id"/>

    <div class="redtext" v-if="inError == true">{{this.errorMessage}}</div>

    <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
        <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead" width="200">ID:</td>
    <td align="left">
    <span v-if="id == 0">Новый</span>
    <span v-if="id > 0">{{id}}</span> 
    </td> 
    </tr>

    <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">Краткое название:<font color="#ff0000">*</font></td>
        <td nobr>
        <input type="text" v-model="name" class="prihodheader" :class="{'vailderror': !isValidHead}" :maxlength="maxLengthHead"/>
        <span class="boldtext">Длина:{{headLength}} (Минимум: {{minLengthHead}}, Максимум: {{maxLengthHead}})</span> 					
        </td>
    </tr>

    <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">Полное название:<font color="#ff0000">*</font></td>
        <td nobr>
        <textarea rows="5" cols="68" v-model="longName" class="prihodfullheader" :class="{'vailderror': !isValidFullHead}" :maxlength="maxLengthFullHead"/>
        <span class="boldtext">Длина:{{fullHeadLength}} (Минимум: {{minLengthFullHead}}, Максимум: {{maxLengthFullHead}})</span> 					
        </td>
    </tr>

    <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">Состояние:<font color="#ff0000">*</font></td>
		<td nobr>
        <select v-model="prihodStateId" :class="{'vailderror': !isValidPrihodState}">
         <option v-for="opt in this.prihodStates" v-bind:key="opt.id" :value="opt.id" :style="'color:' + opt.color">{{opt.name}}</option>
        </select>					
		</td>
    </tr>

    <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">Главный в Благочинии:<font color="#ff0000">*</font></td>
		<td nobr>
        <select v-model="isMainPrihod">
            <option value="false">НЕТ</option>
            <option value="true">ДА</option>
        </select>					
		</td>
    </tr> 

     <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">ВКЛ:<font color="#ff0000">*</font></td>
		<td nobr>
        <select v-model="isVisible">
            <option value="false">НЕТ</option>
            <option value="true">ДА</option>
        </select>					
		</td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead">Сейчас ВКЛ?</td>
    <td nobr>
    <span v-if="nowVisible == 'true'" class="greentext">Да</span>
    <span v-if="nowVisible == 'false'" class="redtext">Нет</span>					
    </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td colspan="2" align="center">
    <button class="btn" v-on:click="trySendShortPrihod()">Далее</button>
    </td>
    </tr>

    </table>
    </div>
</div>
</template>

<script>
import PrihodMenu from '../Prihods/PrihodMenu.vue'

const MinLengthHead = 10;
const MaxLengthHead = 100;
const MinLengthFullHead = 10;
const MaxLengthFullHead = 250;

export default 
{
 name:'PrihodInfo',
 components: {
    PrihodMenu
},
 props:
 {
    id: Number,
    minLengthHead:
    {
    type: Number,
    default: MinLengthHead
    },
    maxLengthHead:
    {
    type: Number,
    default: MaxLengthHead
    },

    minLengthFullHead:
    {
    type: Number,
    default: MinLengthFullHead
    },

    maxLengthFullHead:
    {
    type: Number,
    default: MaxLengthFullHead
    }
 },

 computed:
{
    fullHeadLength: function () {
      return this.longName.length;
    },

    headLength: function () {
      return this.name.length;
    },

    isValidHead: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.name.length >= MinLengthHead) && (this.name.length <= MaxLengthHead);
    },

    isValidFullHead: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.longName.length >= MinLengthFullHead) && (this.longName.length <= MaxLengthFullHead);
    },

    isValidPrihodState: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.prihodStateId > 0);
    },
},

  data(){
    return {
        staticName: String,
        name: String,
        longName: String,
        prihodStateId: Number,
        isMainPrihod: String,
        isVisible: String,
        nowVisible: String,
        loaded : Boolean,
        inError: Boolean,
        isSuccessSave: Boolean,
        isFirstLoadWhenNew: Boolean,
        prihodStates: [],
        errorMessage: String,
    }
 },

methods: {
async getData() {
       try {
        var response = await this.$http.get(this.$restApi + '/prihods/states');
        this.prihodStates = response.data.states;

        if (this.id > 0)
        {
        var prihodInfoResponse = await this.$http.get(this.$restApi +'/prihods/full/' +  this.id);
        if (prihodInfoResponse.status == '404')
        {
          this.$router.push({ name: 'default'});
        }

        var data = prihodInfoResponse.data;
        this.isFirstLoadWhenNew = false;
        this.isVisible = data.visible.toString();
        this.nowVisible = this.isVisible;
        this.name = data.name;
        this.staticName = data.name;
        this.longName = data.longName;
        this.isMainPrihod = data.isMain.toString();
        this.prihodStateId = data.churchState;
        }
        else
        {
          this.isFirstLoadWhenNew = true;
          this.isVisible = 'false';
          this.nowVisible = this.isVisible;
          this.name = '';
          this.staticName = '';
          this.longName = '';
          this.isMainPrihod = 'false';
          this.prihodStateId = 0;
        }

        this.loaded = true;
      } catch (error) 
      {
        console.log(error);
        
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'default'});
        }
      }
    },
    
    async trySendShortPrihod()
    {
        this.isFirstLoadWhenNew = false;
        var isValid = true;
        this.name = this.name.trim();
        this.longName = this.longName.trim();
        this.inError = false;

        if (!this.isValidHead || !this.isValidFullHead || !this.isValidPrihodState)
        {
            isValid = false; 
        }

        if (!isValid){
          return;
        }

          var model = {
              id: this.id,
              name: this.name,
              longName: this.longName,
              isMain: this.isMainPrihod == 'true',
              visible: this.isVisible == 'true',
              churchState: this.prihodStateId
          };

        if (this.id > 0) {
            let response = await this.$http.put(this.$restApi +'/prihods/short/' +  this.id, model);
            if (response.status != 200){
              this.inError = true;
              return;
            }

            this.$router.push({ name: 'editprihodfull', params: { id: this.id } });
        }
        else
        {
            let response = await this.$http.post(this.$restApi +'/prihods/short/', model);
            if (response.status != 200){
              this.inError = true;
              return;
            }

            this.$router.push({ name: 'editprihodfull', params: { id: response.data.id } }); 
        }

    }
 },

  created(){
  this.loaded = false;
  this.getData();
 }

}
</script>

<style scoped>
.prihodheader {width: 290px; height: 30px}

span.boldtext{padding-left: 5px;}
select {width: 130px; height: 30px; cursor: pointer;}
textarea {resize: none;}
</style>