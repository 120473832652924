<template>
<div>
    <router-view :key="$route.fullPath"/>
</div>
</template>

<script>
export default 
{
    name:'NewsEntryPoint',
    props:{
    menuItem: Number,
    },

    mounted(){
      this.$store.commit('formUpdate', {
            menuItem: this.menuItem,
            markupType: 1
        });   
    }
}
</script>

<style scoped>
</style>