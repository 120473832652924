<template>
<div class="tablediv">
    <h1>Раздел "Приходы благочиния"</h1>
    <h2 v-if="id > 0">Наименование прихода тут</h2>
    <PrihodMenu :selectedId="3" :prihodId="this.id"/>

    <br/><br/>
    Пока в разработке...
</div>
</template>

<script>
import PrihodMenu from '../Prihods/PrihodMenu.vue'
export default 
{
 name:'PrihodPriests',
 components: {
    PrihodMenu
},
 props:
 {
     id: Number
 }
}
</script>

<style scoped>
</style>