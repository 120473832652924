<template>
<div v-if="loaded">
    <h1>Раздел "Наши События"- Редактирование</h1>
    <h2 class="likebr">Шаг 1 из 3: Основная информации о событии</h2>
    <span class="greentext" v-if="visible == true">Событие выводится на сайт c {{publicDate}}</span>
    <span class="redtext" v-if="visible == false">Событие пока не выводится на сайт</span>
    <div class="redtext" v-if="inError == true">Не получилось отправить данные. Свяжитесь с разработчиком.</div>

    <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
        <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
	</tr>

     <tr bgcolor="#ffffff">
       <td align="right" class="bluehead" width="200">ID:</td>
       <td align="left">
        <span v-if="id == 0">Новый</span>
        <span v-if="id > 0">{{id}}</span> 
        </td> 
     </tr>
     
     <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">Заголовок:<font color="#ff0000">*</font></td>
		<td nobr>
        <input type="text" v-model="head" class="eventheader" :class="{'vailderror': !isValidHead}" :maxlength="maxLengthHead"/>
        <span class="boldtext">Длина:{{headLength}} (Минимум: {{minLengthHead}}, Максимум: {{maxLengthHead}})</span> 					
		</td>
    </tr>

    <tr bgcolor="#ffffff" >
      <td align="right" class="bluehead">Рубрика:<font color="#ff0000">*</font></td>
      <td>
      <select v-model="rubricId" :class="{'vailderror': !isValidRubric}">
         <option v-for="opt in this.rubrics" v-bind:key="opt.id" :value="opt.id">{{opt.name}}</option>
      </select>
      </td>
    </tr>
				
	<tr bgcolor="#ffffff">
		<td align="right" class="bluehead">Анонс:<font color="#ff0000">*</font></td>
		<td>
    <div class="boldtext"><textarea rows="5" cols="68" v-model="anons" :class="{'vailderror': !isValidAnons}"></textarea></div>
		<div class="boldtext" style="padding-top:5px; padding-bottom:5px">Длина: {{anonsLength}} (Минимум: {{minLengthAnons}}, Максимум: {{maxLengthAnons}})</div>
		</td>
    </tr>

    <tr bgcolor="#ffffff">
    <td colspan="2" align="center">
    <button class="btn" v-on:click="trySendAnons">Далее</button>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
const MinLengthHead = 10;
const MaxLengthHead = 255;
const MaxLengthAnons = 255;
const MinLengthAnons = 70;

export default 
{
 name:'NewsAnons',
 props:
 {
   uid: String,
  minLengthHead:
  {
    type: Number,
    default: MinLengthHead
  },
   maxLengthHead:
   {
    type: Number,
    default: MaxLengthHead
   },

    minLengthAnons:
   {
    type: Number,
    default: MinLengthAnons
   },

    maxLengthAnons:
   {
    type: Number,
    default: MaxLengthAnons
   }
 },

 data(){
    return {
      isFirstLoadWhenNew: Boolean,
      inError: Boolean,
      loaded : Boolean,
      guid: String,
      id: Number,
      visible: Boolean,
      publicDate: String,
      head: String,
      anons: String,
      rubricId: Number,
      rubrics: []
    }
  },
methods:{
 async getData() {
       try {
        var response = await this.$http.get(this.$restApi + '/rubrics');
        this.rubrics = response.data.rubrics;

        if (this.uid != '0')
        {
        var newsResponse = await this.$http.get(this.$restApi +'/news/' +  this.uid + '/anons');
        
        this.id = newsResponse.data.id;
        this.visible = newsResponse.data.visible;
        this.publicDate = newsResponse.data.publicDateRusString + ' ' + newsResponse.data.publicDateRusTime;
        this.head = newsResponse.data.header;
        this.anons = newsResponse.data.anons;
        this.rubricId = newsResponse.data.rubric.id;
        this.isFirstLoadWhenNew = false;
        }
        else
        {
          this.isFirstLoadWhenNew = true;
          this.id = 0;
          this.visible = false;
          this.publicDate = '';
          this.head = '';
          this.anons = '';
          this.rubricId = 0;
        }

        this.loaded = true;
      } catch (error) 
      {
        console.log(error);
        
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'eventslist'});
        }
      }
 },

 async trySendAnons(){
   try{
        this.isFirstLoadWhenNew = false;
        var isValid = true;
        this.head = this.head.trim();
        this.anons = this.anons.trim();
        this.inError = false;

        if (!this.isValidHead || !this.isValidAnons || !this.isValidRubric)
        {
            isValid = false; 
        }

        if (isValid)
        {
          var model = {
              id: this.id, 
              header: this.head,
              anons: this.anons,
              rubricId: this.rubricId,
              uid: this.uid
          };
          var newsGuid = this.uid;

          if (this.uid != '0')
          {
            let response = await this.$http.put(this.$restApi +'/news/' +  this.uid + '/anons', model);

            if (response.status != 200)
            {
              this.inError = true;
              return;
            }
          }
          else
          {
            let response = await this.$http.post(this.$restApi +'/news/anons/new', model);
            if (response.status != 200)
            {
              this.inError = true;
              return;
            }
            newsGuid = response.data.uid;
          }


          this.$router.push({ name: 'editeventfull', params: { id: newsGuid } });
        }

      } catch (error) {
        this.inError = true;
        console.log(error);
      }
 }

},

computed:
{
    anonsLength: function () {
      return this.anons.length;
    },

    headLength: function () {
      return this.head.length;
    },

    isValidHead: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.head.length >= MinLengthHead) && (this.head.length <= MaxLengthHead);
    },

    isValidAnons: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.anons.length >= MinLengthAnons) && (this.anons.length <= MaxLengthAnons);
    },

    isValidRubric: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.rubricId > 0);
    },
},

  created(){
  this.loaded = false;
  this.getData();
 }
}
</script>

<style scoped>
div.redtext {padding-top: 10px;}

.eventheader {width: 400px;}

span.boldtext{padding-left: 5px;}

select {width: 200px; height: 30px; cursor: pointer;}

input {height: 30px}

textarea {resize: none;}

.eventheader{width: 400px;}

.vailderror{border:2px solid red; background-color:#f1888841}
</style>