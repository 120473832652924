<template>
<div>
    <a v-for="index in totalPages" :key="index" @click="changePage(index)" :class="{ active: index ==  pageNumber}" href="#">{{index}}</a>
</div>
</template>

<script>
export default 
{
name:'Pager',
data(){
return {
    pageNumber : Number,
    totalPages: Number
    }
},
methods:{
changePage: function(nextPage){
    this.pageNumber = nextPage;
    this.$emit('changePageEvent', nextPage);
 },
}
}
</script>

<style scoped>
a {padding-right: 10px; color:#000099}
a.active{
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: Red;
}
</style>