<template>
<div>
    <table cellSpacing="4" align="center" border="0" style="padding-top:200px;">
    <tr>
        <td colspan="2" align="center"><img src="../../assets/ns_logo.jpg" border="0"/></td>
    </tr>

    <tr> 
        <td colspan="2" align="left">
        <div class="redtext" v-if="inError == true"><ul class="error_ul" v-html="this.errorText"></ul></div></td>
    </tr>

    <tr>       
    <td>
    <span class="spantext">Логин:</span>
    <input type="text" class="textheader" v-model="loginText" v-on:change="onChangeText()" :class="{'vailderror': !isValidLoginText}" :maxlength="maxLengthLogin"/>
    <span class="spantext">&nbsp;</span>
    <span class="spantext">Пароль:</span>
    <input type="password" class="textheader" v-model="password" v-on:change="onChangeText()" :class="{'vailderror': !isValidPasswordText}" :maxlength="maxLengthPassword"/>
    </td>
    <td>
        <button class="btn add_btn" v-on:click="login()">Войти »</button>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
const MaxLengthLogin = 20;
const MaxLengthPassword = 30;
const MinLengthPassword = 6;

export default 
{
name:'LoginComponent',

components: {
},

props:{
    maxLengthLogin:
   {
    type: Number,
    default: MaxLengthLogin
   },

    maxLengthPassword:
   {
    type: Number,
    default: MaxLengthPassword
   },

    minLengthPassword:
   {
    type: Number,
    default: MinLengthPassword
   },   
},

data(){
    return {
        errorText: String,
        inError: Boolean,
        isFirstLoadWhenNew: Boolean,
        loginText: String,
        password: String,
    }
},

computed:{
    isValidLoginText: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }
        return (this.loginText.length >= 1) && (this.loginText.length <= MaxLengthLogin);
    },

    isValidPasswordText: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }
        return (this.password.length >= MinLengthPassword) && (this.password.length <= MaxLengthPassword);
    }, 
},

methods:{
 getData() {
        this.errorText = '';
        this.loginText = '';
        this.password = '';
        this.isFirstLoadWhenNew = true;
        this.inError = false;
    },

onChangeText(){
        this.isFirstLoadWhenNew = false;
        this.errorText = '';
        this.inError = false;
    },

    async login(){
        this.onChangeText();
        this.errorText = '';
        if (!this.isValidLoginText)
        {
            this.errorText = '<li>Введите логин!</li>';
            this.inError = true;
        }

        if (!this.isValidPasswordText)
        {
            this.errorText += '<li>Введите пароль от '  + this.minLengthPassword + ' до ' + this.maxLengthPassword + ' символов!</li>';
            this.inError = true;
        }

        if (this.inError)
        {   
            return;
        }

        try {
            var response = await this.$http.post(this.$loginApi + '/login/',
            {
                login: this.loginText,
                password: this.password,
            });   

            if (response.status == 200)
            {   
                var whereToGo = await this.$store.dispatch('resolveSuccessLogin', response.data);
                this.$router.push(whereToGo);
            }
            else
            {
                this.errorText = response.data;
                this.inError = true;  
            }
            
        } 
        catch (error) {
            this.inError = true;
            this.errorText = error.response.data;
            console.log(error);
        }       
    }
},

  created(){
  this.getData();
    },

   mounted(){
    this.$store.commit('formUpdate', {
            markupType: 2
        });
    }
}
</script>

<style scoped>
.vailderror{border:2px solid red; background-color:#f1888841}

.textheader{font-size: 16px; width: 150px; height:25px;}

.spantext{font-size: 14px; font-weight: bold; padding-right: 10px;}

.error_ul{padding-left: 25px !important;}
</style>