<template>
<div class="tablediv">
    <h1>Раздел "Приходы благочиния"</h1>

    <h2 v-if="id > 0"><span v-html="this.prihodModel.name"/></h2>

    <PrihodMenu :selectedId="5" :prihodId="this.id"/>

    <div class="redtext" v-if="inError == true">Ошибка при работе с Альбомом. Свяжитесь с разработчиком.</div>
    <div v-if="isSuccessSave == true" class="ok">Сохранение успешно!</div>
    <table cellspacing="1" cellpadding="3" width="1000px" border="0" class="child" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
    <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead" width="200">ID:</td>
    <td align="left">
    <span v-if="albumId == 0">Новый</span>
    <span v-if="albumId > 0">{{albumId}}</span> 
    </td> 
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead">Заголовок Альбома:<font color="#ff0000">*</font></td>
    <td nobr>
    <input type="text" v-model="albumName" v-on:change="onchangeFields" class="albumheader" :class="{'vailderror': !isValidAlbumName}" :maxlength="maxLengthAlbumName"/>
    <span class="boldtext">Длина:{{albumNameLength}} (Минимум: {{minLengthAlbumName}}, Максимум: {{maxLengthAlbumName}})</span> 					
    </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead">Описание Альбома:<font color="#ff0000">*</font></td>
    <td>
    <div class="boldtext"><textarea rows="5" cols="68" v-on:change="onchangeFields" v-model="albumAbout" :class="{'vailderror': !isValidAlbumAbout}"></textarea></div>
    <div class="boldtext" style="padding-top:5px; padding-bottom:5px">Длина: {{albumAboutLength}} (Минимум: {{minLengthAlbumAbout}}, Максимум: {{maxLengthAlbumAbout}})</div>
    </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead">ВКЛ:<font color="#ff0000">*</font></td>
    <td nobr>
    <select v-model="isVisible" v-on:change="onchangeFields">
    <option value="false">НЕТ</option>
    <option value="true">ДА</option>
    </select>					
    </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead">Сейчас ВКЛ?</td>
    <td nobr>
    <span v-if="nowVisible == true" class="greentext">Да</span>
    <span v-if="nowVisible == false" class="redtext">Нет</span>					
    </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td colspan="2" align="center">
    <button class="btn" v-on:click="save">Сохранить</button>
    </td>
    </tr>

    </table>

    <div class="child" style="padding-top:10px;padding-bottom:10px;" v-if="this.albumId == 0">Перед тем, как добавить фотографии, введите и сохраните описание Альбома.</div>

    <div class="child" style="padding-top:10px;padding-bottom:10px;" v-if="this.albumId > 0">
    <div class="inputfilediv">
        <input type="file" ref="file" v-on:change="handleFileUpload()" class="inputfile">
        <input type="image" :src='require("@/assets/add_small.png")' class="inputimages" v-on:click="handleFileUpload();submitFile()"/>
        <span class="redtext" v-if="fileErrorText.length > 0">{{fileErrorText}}</span>    
    </div>

    <div class="fatdiv fieldset" v-if="(pictures !== null) && (pictures.length > 0)">
      <div v-for="pic in this.pictures" v-bind:key="pic.pictureId">
        <div class="divimages">
          <div>
            <input type="radio" name="mainPicture" v-model="mainPicture" :value="pic.pictureId" class="inputimages">
          </div>
          <div>
            <img alt="" style="margin-right:5px" :width="pic.standardWidth" :height="pic.standardHeight" :src="pic.standardUrl"/>
          </div>
          <div>
            <input type="image" :src='require("@/assets/delete_small.png")' :value="pic.pictureId" class="inputimages" v-on:click="deletePicture(pic.pictureId)"/>
            <br/>
            <span class="bluehead">
              {{pic.standardWidth}}x{{pic.standardHeight}}<br/>
              {{pic.bigWidth}}x{{pic.bigHeight}}
            </span>
          </div>
        </div>
      </div>
    </div>	

    </div>
</div>
</template>

<script>
const MinLengthAlbumName = 5;
const MaxLengthAlbumName= 255;
const MaxLengthAlbumAbout = 255;
const MinLengthAlbumAbout = 10;

import PrihodMenu from '../Prihods/PrihodMenu.vue';

export default 
{
name:'PrihodAlbumEdit',
 components: {
    PrihodMenu
},
props:
{
    id: Number,
    albumId: Number,
    minLengthAlbumName:
    {
        type: Number,
        default: MinLengthAlbumName
    },
    maxLengthAlbumName:
    {
        type: Number,
        default: MaxLengthAlbumName
    },

    minLengthAlbumAbout:
    {
        type: Number,
        default: MinLengthAlbumAbout
    },

    maxLengthAlbumAbout:
    {
        type: Number,
        default: MaxLengthAlbumAbout
    }
},

computed:
{
    albumAboutLength: function () {
        return this.albumAbout.length;
    },

    albumNameLength: function () {
        return this.albumName.length;
    },

    isValidAlbumName: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.albumName.length >= MinLengthAlbumName) && (this.albumName.length <= MaxLengthAlbumName);
    },

    isValidAlbumAbout: function () {
      if (this.isFirstLoadWhenNew)
      {
        return true;
      }

      return (this.albumAbout.length >= MinLengthAlbumAbout) && (this.albumAbout.length <= MaxLengthAlbumAbout);
    },
},

data(){
    return {
        prihodModel: Object,
        loaded: Boolean,
        isFirstLoadWhenNew: Boolean,
        isSuccessSave : Boolean,
        inError: Boolean,
        nowVisible: Boolean,
        albumName: String,
        albumAbout: String,
        isVisible: String,
        file: String,
        inFileError: Boolean,
        fileErrorText: String,
        pictures : [],
        mainPicture : Number,
    }
  },

methods:{
    async deletePicture(pictureId)
  {
  try {
        await this.$http.delete(this.$restApi + '/prihods/' + this.id + '/albums/' +  + this.albumId+ '/photos/'+ pictureId);
        await this.getAlbumPrihodPictures();
    
      } catch (error) {
        this.fileErrorText = 'Не получилось удалить фотографию! Свяжитесь с разработчиком.';
        console.log(error);
      }
  },
    async getAlbumPrihodPictures() {
    try {
        var response = await this.$http.get(this.$restApi + '/prihods/' + this.id + '/albums/' + this.albumId + '/photos');
        this.pictures = response.data.pictures;
        this.pictures.forEach(element => {
          if (element.isMain)
          {
            this.mainPicture = element.pictureId;
          }
        });   
      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
        try {

        if (this.id <= 0)
        {
          this.$router.push({ name: 'default'});
        }
        
        this.loaded = false;
        this.inError = false;
        this.isSuccessSave = false;
        this.fileErrorText = '';
        var responsePrihod = await this.$http.get(this.$restApi + '/prihods/' + this.id);
        this.prihodModel = responsePrihod.data;

        if (this.albumId == 0)
        {
            this.albumName = '';
            this.albumAbout = '';
            this.isVisible = 'false';
            this.nowVisible = false;
            this.loaded = true;
            this.inError = false;
            this.isFirstLoadWhenNew = true;
            return;
        }

        var responseAlbum = await this.$http.get(this.$restApi + '/prihods/' + this.id + '/album/' + this.albumId);
        var prihodAlbumModel = responseAlbum.data;
        this.nowVisible = prihodAlbumModel.visible;
        this.albumName = prihodAlbumModel.name;
        this.albumAbout = prihodAlbumModel.about;
        this.isVisible = prihodAlbumModel.visible.toString();
        this.isFirstLoadWhenNew = false;
        await this.getAlbumPrihodPictures();

        this.loaded = true;
        } catch (error) {
        console.log(error);
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'prihodslist'});
        }
        }
    },

    onchangeFields()
    {
        this.isSuccessSave = false;
    },

    async save()
    {
        try{
        this.fileErrorText = '';
        this.inError = false;
        this.isFirstLoadWhenNew = false;
        this.isSuccessSave = false;
        this.albumName = this.albumName.trim();
        this.albumAbout = this.albumAbout.trim();
        if (!this.isValidAlbumName || !this.isValidAlbumAbout)
        {
            return;
        }

         if (this.albumId != 0)
        {
            await this.$http.put(this.$restApi + '/prihods/' +  this.id + '/albums/' + this.albumId, 
            {
                id: this.albumId,
                prihodId: this.id,
                name: this.albumName,
                about: this.albumAbout,
                visible: this.isVisible === 'true',
                mainPictureId: this.mainPicture
            });

            if (this.mainPicture > 0)
            {
              await this.$http.post(this.$restApi +'/prihods/' +  this.id + '/albums/' + this.albumId + '/photos/main/' + this.mainPicture, 
              {
              });
            }

            this.isSuccessSave = true;
            this.nowVisible = this.isVisible === 'true';
        }
        else
        {
            let response = await this.$http.post(this.$restApi +'/prihods/' +  this.id + '/albums/new', 
            {
                prihodId: this.id,
                name: this.albumName,
                about: this.albumAbout,
                visible: this.isVisible === 'true'
            });
            var newAlbumId = response.data.id;
            this.$router.push({ name: 'editprihodalbum', params: { id: this.id, albumId: newAlbumId } });
        }
        }
        catch(error){
        this.inError = true;
        console.log(error);
        }
    },

    handleFileUpload(){
    this.inFileError = false;
    this.fileErrorText = '';
    if (this.$refs.file.files.length > 0 )
    {
     this.file = this.$refs.file.files[0];
    }
    else
    {
      this.file = '';
    }
  },

    async submitFile(){
    try{
        if (this.file == '')
        {     
          this.fileErrorText = 'Файл не выбран!';
          return;
        }

        let formData = new FormData();
        formData.append('file', this.file);
        var response = await this.$http.post(this.$restApi +'/prihods/' +  this.id + '/albums/' + this.albumId + '/photos/new',formData, {
        headers: {'Content-Type': 'multipart/form-data'}});

        if (response.status != 200)
        {
        this.inFileError = true;
        this.fileErrorText = '';
        return;
        }

        this.inFileError = false;
        this.fileErrorText = '';
        this.file = '';
        await this.getAlbumPrihodPictures();
    }
    catch(error)
    {
     console.log(error);
     this.inFileError = true;
     if (typeof(error.response) == 'undefined')
     {
        this.fileErrorText = error.message;
     }
     else
     {
        this.fileErrorText = error.response.data;
     }
    }
  },


},
created(){
  this.getData();
 }
}
</script>

<style scoped>
div.redtext {padding-top: 10px;}

select {width: 60px; height: 30px; cursor: pointer;}

input {height: 30px}

textarea {resize: none;}

span.boldtext{padding-left: 5px;}

.albumheader{width: 300px;}

.inputimages{
  vertical-align: top;
  cursor: pointer;
  margin-right:7px;
  margin-bottom:5px;
  height:auto;
}

.inputfile{cursor: pointer;}
.inputfile:hover{cursor: pointer;}

.divimages{
  float:left;
  padding-bottom: 7px;
  padding-right: 7px;
  width:310px;
  height:200px;
}

.divimages div{
  float:left;
}

.fatdiv{width: 1000px; padding-top: 10px;}
.fatdiv.fieldset{height:550px; margin-left:0px;}
</style>