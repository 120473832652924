<template>
<div class="tablediv">
    <h1>Раздел "Приходы благочиния"</h1>
    <div v-if="loaded">
        <h2><span v-html="this.name"/></h2>
        <PrihodMenu :selectedId="2" :prihodId="this.id"/>

        <div class="redtext" v-if="inError == true">Ошибка при работе с Расписанием. Свяжитесь с разработчиком.</div>
        <div v-if="isSuccessSave == true" class="ok">Сохранение успешно!</div>

        <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:10px;">
        <tr bgcolor="#ffffff">
        <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
        </tr>

        <tr bgcolor="#ffffff">
        <td align="right" class="bluehead" width="150px">Картинки:</td>
        <td nobr >
          <div class="addpicdiv" :class="{ 'vailderror': !isValidPictures }">
            <div class="redtext" v-if="inFileError == true">Не получилось добавить фото: {{ fileErrorText }}</div>
            <div>
              <input type="file" ref="file" v-on:change="handleFileUpload()">
            </div>
            <div style="cursor:pointer; padding-top:5px">
              <input type="radio" name="whatToDo" id ="rgFirst" v-model="whatToDo" value="1">
              <label for="rgFirst">Создать картинку стандартного размера</label>
            <br>
            <input type="radio" name="whatToDo" id="rgSecond" v-model="whatToDo" value="2">
            <label for="rgSecond">Создать картинку стандартного и увеличенного размеров</label>
            </div>
            <div>
              <button style="cursor:pointer; margin:5px;" v-on:click="submitFile()">Добавить</button>
            </div>
          </div>

          <div class="fieldset" v-if="(pictures !== null) && (pictures.length>0)">
                <div v-for="pic in this.pictures" v-bind:key="pic.pictureId">
                  <div class="divimages">
                    <div>
                      <img alt="" style="margin-right:5px" width="100" height="100" :src="pic.smallUrl"/>
                    </div>
                    <div>
                      <input type="image" :src='require("@/assets/delete_small.png")' :value="pic.pictureId" class="inputimages" v-on:click="deletePicture(pic.pictureId)"/><br/>
                      <span class="bluehead" title="Вставить в текст" v-on:click="copyToEditor(makeInsertHtml(pic.pictureId, pic.standardWidth, pic.standardHeight, pic.standardUrl, pic.bigUrl))">
                        {{pic.standardWidth}}x{{pic.standardHeight}}
                        <input type="image" :src='require("@/assets/zoom_in_small.png")' class="inputimages" v-if="pic.bigWidth > 0"/>
                        <input type="image" :src='require("@/assets/zoom_in_blank.png")' class="inputimages" v-if="pic.bigWidth == 0"/> 
                      </span>
                    </div>
                  </div>
                </div>
          </div>		
        </td>
        </tr>

        <tr bgcolor="#ffffff" >
        <td align="right" class="bluehead">Полный текст:<font color="#ff0000">*</font></td>
        <td :class="{'vailderror': !isValidFull}">
        <Wysiwyg ref="fullEditor" width="760" :content="fullText" height="450" marginLeft="10"/>
        </td>
        </tr>
        <tr bgcolor="#ffffff">
        <td colspan="2" align="center">
        <button class="btn" v-on:click="save">Сохранить</button>
        </td>
        </tr>
        </table>
    </div>
</div>
</template>

<script>
import PrihodMenu from './PrihodMenu.vue'
import Wysiwyg from '../Wysiwyg.vue'
import { WhatToDoWithPrihodPhoto } from '../../settings/WhatToDoWithPrihodPhoto.ts'; 

export default 
{
 name:'PrihodFull',
 components: {
    PrihodMenu,
    Wysiwyg
},
 props:
 {
     id: Number
 },

computed:{
 isValidFull: function () {
    if (this.isFirstLoadWhenNew)
    {
        return true;
    }

    if (this.fullText.length > 0)
    {
    return true;
    }

    return false;
    }
},

 data()
 {
  return {
    isFirstLoadWhenNew: Boolean, 
    loaded: Boolean,
    inError: Boolean,
    name: String,
    fullText: String,
    isSuccessSave: Boolean,
    pictures: [],
    inFileError: Boolean,
    fileErrorText: String,
    file: String,
    whatToDo: Number,
    isValidPictures: Boolean,
    }
 },
 methods: {
    handleFileUpload(){
    this.inFileError = false;
    this.fileErrorText = '';
    this.isValidPictures = true;
    this.file = this.$refs.file.files[0];
  },

  makeInsertHtml(id, width, height, url, bigUrl){
    if (bigUrl != '' && bigUrl != null)
    {
      return '<a class="sys_piclens" id="piclens' + id +'" href="'+ bigUrl
      +'" title="" txt=""><img align="left" style="margin-right:5px; margin-bottom:5px;" border="0" alt="" width="'
      + width +'"  height="' + height + '" src="'+ url +'"/></a>';                
    }
    
    return '<img alt="" align="left" style="margin-right:5px; margin-bottom:5px;" width="'
      + width + '"  height="'+ height + '"  src="'+ url + '"/>';
  },

  copyToEditor(strHtml){
    this.$refs.fullEditor.insertAtCursor(strHtml);
    return false;
  },

  async submitFile(){
    try{

      if (this.file == '')
      {     
        throw new Error('Файл не выбран!');
      }

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('whatToDo', this.whatToDo);
      var response = await this.$http.post(this.$restApi +'/prihods/full/' +  this.id + '/photo',formData, {
        headers: {'Content-Type': 'multipart/form-data'}});
      
      if (response.status != 200)
      {
        this.inFileError = true;
        return;
      }

      this.inFileError = false;
      this.fileErrorText = '';
      await this.getPictures();
    }
    catch(error)
    {
      console.log(error);
     this.inFileError = true;
     if (typeof(error.response) == 'undefined')
     {
        this.fileErrorText = error.message;
     }
     else
     {
        this.fileErrorText = error.response.data;
     }
    console.log(error);
    }
  },

  async deletePicture(pictureId)
  {
  try {
        await this.$http.delete(this.$restApi + '/prihods/full/' + this.id + '/photos/'+ pictureId);
        await this.getPictures();
    
      } catch (error) {
        console.log(error);
      }
  },

  async getPictures() {
  try {
        var response = await this.$http.get(this.$restApi + '/prihods/full/' +  this.id + '/photos');
        this.pictures = response.data.pictures;    
      } catch (error) {
        console.log(error);
      }
  },

  async getData() {
       try {
        this.loaded = false;
        this.inFileError = false;
        this.fileErrorText = '';
        this.isValidPictures = true;
        this.whatToDo = WhatToDoWithPrihodPhoto.Standard;

        if (this.id <= 0)
        {
            this.$router.push({ name: 'default'});
        }

        var prihodInfoResponse = await this.$http.get(this.$restApi +'/prihods/full/' +  this.id);
        if (prihodInfoResponse.status == '404')
        {
          this.$router.push({ name: 'default'});
        }

        var data = prihodInfoResponse.data;
        this.fullText = data.fullText;
        this.name = data.name;

        await this.getPictures();

        this.loaded = true;
      } catch (error) 
      {
        console.log(error);
        
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'default'});
        }
      }
    },
    async save()
    {
        this.isFirstLoadWhenNew = false;  
        this.isSuccessSave = false;
        this.fullText = this.$refs.fullEditor.contentEditor;
        var response = await this.$http.put(this.$restApi +'/prihods/full/' + this.id, 
          {
            id: this.id,
            fullText: this.fullText,
          });

        if (response.status != 200)
        {
            this.inError = true;
            return;
        }

        this.isSuccessSave = true;
    },

 },

  created(){
  this.loaded = false;
  this.getData();
  this.isFirstLoadWhenNew = true;  
 }

}
</script>

<style scoped>
.divimages{
  float:left;
  cursor: pointer;
  padding-bottom: 7px;
  padding-right: 7px;
}

.divimages div{
  float:left;
  margin-left: 5px;
}

.inputimages{
  vertical-align: top;
  cursor: pointer;
  margin-right:7px;
  margin-bottom:5px;
  height:auto;
}

span.bluehead{
  text-decoration-line: underline;
}
</style>