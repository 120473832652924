<template>
<div style="align:right; padding-left:15px;">
    <h1>Раздел "Наши События"</h1>
    <table class="child" cellspacing="0" cellpadding="3" style="border-color:#0066FF;border-width:2px;border-style:solid;width:900px;border-collapse:collapse;">   
    <tr align="left" style="color:#000099;background-color:#C1E0FF;border-color:#3333CC;font-weight:bold;">
    <td colspan="6">
    <Pager ref="pager1" @changePageEvent="changePageEventHandler"/>
    </td>
	</tr>

    <tr align="center" style="background-color:White;font-weight:bold;">
			<td><span class="header">ID</span></td>
            <td><span class="header">Заголовок</span></td>
            <td><span class="header">Дата публикации</span></td>
            <td><span class="header">Вкл</span></td>
            <td><span class="smalltext">Изменить</span></td>
            <td><span class="smalltext">Удалить</span></td>
	</tr>

     <tr v-for="(newsItem, index) in newsModel.news" v-bind:key="newsItem.id" :class="classForTrItem(index)">
        <td align="center">{{newsItem.id}}</td>
        <td align="center">{{newsItem.header}}</td>
        <td align="center">{{newsItem.publicDateRusString}}</td>
        <td align="center">
            <span v-if="newsItem.visible == true" class="greentext">Да</span>
            <span v-if="newsItem.visible == false" class="redtext">Нет</span>
        </td>
        <td align="center">
          <router-link :to="{name:'editeventanons', params:{id:newsItem.uid}}"><img src="../../assets/edit.png" border="0" alt="Редактировать"></router-link>
        </td>
        <td align="center">  
        <router-link :to="{name:'eventdelete', params:{id:newsItem.uid}}"><img src="../../assets/delete.png" class="imgpointer" border="0" alt="Удалить"></router-link>
        </td>
    </tr>
    <tr align="left" style="color:#000099;background-color:#C1E0FF;border-color:#3333CC;font-weight:bold;">
    <td colspan="6">
    <Pager ref="pager2" @changePageEvent="changePageEventHandler"/>
    </td>
    </tr>
</table>
<div class="likebr" style="float:right">
  <b>Добавить событие</b>:
  <router-link :to="{name:'editeventanons', params:{id:'0'}}"><img src="../../assets/add.png" border="0" alt="Добавить"></router-link>
</div>
</div>
</template>

<script>
import Pager from '../Pager.vue'
export default 
{
name:'NewsList',
 data(){
    return {
      pageNumber: Number,
      newsModel: Object
    }
  },
components: {
Pager
},
methods:{
    classForTrItem: function (trNumber) {
      return (trNumber + 1) % 2  == 0 ? 'maintr' : 'alternatetr';
    },
    async getData(pageNumber) {
       try {
        var response = await this.$http.get(this.$restApi + '/news?pageNumber=' + pageNumber +'&pageSize=' + this.pageSize);
        this.newsModel = response.data;
        
        this.$refs.pager1.totalPages = this.newsModel.pageSizes.totalPages;
        this.$refs.pager1.pageNumber = pageNumber;

        this.$refs.pager2.totalPages = this.newsModel.pageSizes.totalPages;
        this.$refs.pager2.pageNumber = pageNumber;

      } catch (error) {
        console.log(error);
      }
    },

  changePageEventHandler: function(nextPage)
  {
    this.getData(nextPage); 
  }

},
props:{
    pageSize: Number,
},

created(){
  this.getData(1);
 }
}
</script>

<style scoped>
.child{font-size: 13px; text-decoration: none;}
.child td {border:1px solid; border-bottom-color: #0066ff; border-top-color: #0066ff; border-right-color: #0066ff; border-left-color: #0066ff;}
.header{color:#000099}
.maintr{background-color:#FFFFE8;}
.alternatetr{background-color:#DDFFEF;}
</style>