<template>
<div>
    <span v-for="menuItem in items" :key="menuItem.menuId" class="spanmenu">
    <router-link :to="{name: selectRoute(menuItem.menuId), params:{id: prihodId}}" :class="classForItem(menuItem.menuId)">{{menuItem.menuName}}</router-link>
    </span>
</div>
</template>

<script>
export default 
{
 name:'PrihodMenu',
 props:
 {
     selectedId: Number,
     prihodId: Number
 },
 data(){
    return {
      items: [
        {
            menuId:1,
            menuName: 'Приход'
        },
        {
            menuId:2,
            menuName: 'Описание'
        },
         {
            menuId:3,
            menuName: 'Духовенство'
        },
        {
            menuId:4,
            menuName: 'Расписание литургий'
        },
        {
            menuId:5,
            menuName: 'ФотоАльбомы'
        }   
      ]
    }
  },
  methods:{
    classForItem: function (currentId) {
      return this.selectedId  == currentId ? 'prihodmenuactive' : 'prihodmenu';
    },
    selectRoute: function(menuId)
    {
      switch (menuId) {
        case 1:
          return "editprihodinfo";
        case 2:
          return "editprihodfull";
        case 3:
          return "editprihodpriests";
        case 4:
          return "editprihodtimetable";
        case 5:
          return "editprihodalbums";                             
        default: return "default";
      }
    }, 
  }
}
</script>

<style scoped>
.spanmenu{
  padding-right:18px;
}
.prihodmenu {
  font-size:14px;
  color:#27274e;
}

.prihodmenuactive {
  font-size: 16px;
  color: Red;
  font-weight: bold;
}
</style>