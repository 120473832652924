<template>
<div class="tablediv" v-if="loaded">
  <h1>Раздел "Приходы благочиния"</h1>

  <h2 v-if="id > 0"><span v-html="this.prihodModel.name"/></h2>

  <PrihodMenu :selectedId="4" :prihodId="this.id"/>
    
    <div class="redtext" v-if="inError == true">Ошибка при работе с Расписанием. Свяжитесь с разработчиком.</div>
    <div v-if="isSuccessSave == true" class="ok">Сохранение успешно!</div>

    <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:20px;">
    <tr bgcolor="#ffffff" >
      <td align="right" class="bluehead">Расписание:</td>
      <td>
        <Wysiwyg ref="fullEditor" width="760" :content="timeTableText" height="450" marginLeft="10"/>
      </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td colspan="2" align="center">
    <button class="btn" v-on:click="save">Сохранить</button>
    </td>
    </tr>

    </table>
</div>
</template>

<script>
import PrihodMenu from '../Prihods/PrihodMenu.vue';
import Wysiwyg from '../Wysiwyg.vue';

export default 
{
 name:'PrihodTimeTable',
 components: {
    PrihodMenu,
    Wysiwyg
},
 props:
 {
     id: Number
 },

 data(){
    return {
        timeTableText: String,
        prihodModel: Object,
        loaded : Boolean,
        inError: Boolean,
        isSuccessSave: Boolean
    }
 },

 methods:{
    async getData() {
    try 
    {
      if (this.id <= 0)
      {
        this.$router.push({ name: 'default'});
      }

      var responsePrihod = await this.$http.get(this.$restApi + '/prihods/' + this.id);
      this.prihodModel = responsePrihod.data;
      
      var response = await this.$http.get(this.$restApi + '/prihods/' +  this.id + '/timetable');
      this.prihodName = this.prihodModel.name;
      this.timeTableText = response.data.timeTableText;
      this.loaded = true;
      this.inError = false;
      this.isSuccessSave = false;
    } catch (error) {
    console.log(error);

    if (error.response.status == '404')
    {
        this.$router.push({name: 'default'});
    }

    this.prihodName = '';
    this.timeTableText = '';
    this.loaded = true;
    this.inError = true;
    this.isSuccessSave = false;

    }
},

    async save()
    {
        this.isSuccessSave = false;
        this.timeTableText = this.$refs.fullEditor.contentEditor;
        var response = await this.$http.put(this.$restApi +'/prihods/' +  this.id + '/timetable', 
          {
            prihodId: this.id,
            timeTableText: this.timeTableText,
          });

        if (response.status != 200)
        {
            this.inError = true;
            return;
        }

        this.isSuccessSave = true;
    },
 },

  created(){
    this.loaded = false;
    this.getData();   
 }
}
</script>

<style scoped>
div.redtext {padding-top: 10px;}
</style>