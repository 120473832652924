<template>
<div class="tablediv">
  <h1>Раздел "Приходы благочиния"</h1>
  
    <table class="child tablestyling" cellspacing="0" cellpadding="3">
    <tr align="center" style="background-color:White;font-weight:bold;">
    <td><span class="header">ID</span></td>
    <td><span class="header">Название</span></td>
    <td><span class="header">Тип</span></td>
    <td><span class="header">Состояние</span></td>
    <td><span class="header">Вкл</span></td> 
    <td><span class="smalltext">Изменить</span></td>
    </tr>

    <tr v-for="(prihodItem, index) in prihodsModel.prihods" v-bind:key="prihodItem.id" :class="classForTrItem(index)">
    <td align="center">{{prihodItem.id}}</td>
    <td align="center"><span v-html="prihodItem.name"/></td>
    <td align="center" :class="classForIsMain(prihodItem.isMain)">{{textForIsMain(prihodItem.isMain)}}</td>
    <td align="center" :class="classForPrihodState(prihodItem.churchState)"><b>{{prihodItem.churchStateDescription}}</b></td> 
    <td align="center">
    <span v-if="prihodItem.visible == true" class="greentext">Да</span>
    <span v-if="prihodItem.visible == false" class="redtext">Нет</span>
    </td>
    <td align="center">
    <router-link :to="{name:'editprihodinfo', params:{id:parseInt(prihodItem.id)}}"><img src="../../assets/edit.png" border="0" alt="Редактировать"></router-link>
    </td>
    </tr>
    </table>
    <div class="likebr" style="float:right;">
    <b>Добавить приход</b>:
    <router-link :to="{name:'editprihodinfo', params:{id:'0'}}"><img src="../../assets/add.png" border="0" alt="Добавить"></router-link>
    </div>   
</div>
</template>

<script>
export default 
{
name:'PrihodsList',
 data(){
    return {
      prihodsModel: Object
    }
  },
methods:{
    classForTrItem: function (trNumber) {
      return (trNumber + 1) % 2  == 0 ? 'maintr' : 'alternatetr';
    },

    classForPrihodState: function (churchState){
      if (churchState == 2)
      {
        return 'bluetext';
      }

      if (churchState == 3)
      {
        return 'redtext';
      }

      return 'greentext';
    },

    classForIsMain : function (isMain)
    {
      if (isMain)
      {
        return 'greentext';
      }
    },

    textForIsMain : function (isMain)
    {
      if (isMain)
      {
        return 'ГЛАВНЫЙ';
      }

      return 'неглавный'
    }, 

    async getData() {
       try {
        var response = await this.$http.get(this.$restApi + '/prihods');
        this.prihodsModel = response.data;
      } catch (error) {
        console.log(error);
      }
    },
},

created(){
  this.getData();
 }
}
</script>

<style scoped>
.tablestyling{
border-color:#0066FF;
border-width:2px;
border-style:solid;
width:900px;
border-collapse:collapse;
}

.tablediv{padding-left:15px;}
.child{font-size: 13px; text-decoration: none;}
.child td {border:1px solid; border-bottom-color: #0066ff; border-top-color: #0066ff; border-right-color: #0066ff; border-left-color: #0066ff;}
.header{color:#000099}
.maintr{background-color:#FFFFE8;}
.alternatetr{background-color:#DDFFEF;}
</style>