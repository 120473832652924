<template>
<div class="tablediv">
    <h1>Раздел "Приходы благочиния"</h1>

    <h2 v-if="id > 0"><span v-html="this.prihodModel.shortName"/></h2>

    <PrihodMenu :selectedId="5" :prihodId="this.id"/>

    <div class="redtext likebr" v-if="inError == true">Ошибка при работе с удалением Альбома. Свяжитесь с разработчиком.</div>
    <table cellspacing="1" cellpadding="3" border="0" class="child tablestyling" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
    <td align="center" class="bluehead bigtext" width="200">Вы действительно хотите удалить альбом c id = {{albumId}} и наименованием "{{albumName}}"?</td>
    </tr>
    <tr bgcolor="#ffffff">
    <td align="center">
    <button class="btn" v-on:click="deleteAlbum()">Да</button>&nbsp;&nbsp;
    <button class="btn" v-on:click="cancelDelete()">Нет</button>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
import PrihodMenu from '../Prihods/PrihodMenu.vue';

export default 
{
name:'PrihodAlbumDelete',
components: {
    PrihodMenu
},
props:
{
    id: Number,
    albumId: Number,
},
data(){
    return {
        albumName: String,  
        inError: Boolean,
        prihodModel: Object,
    }
},
methods:{
        async getData() {
        try {

        if (this.id <= 0)
        {
          this.$router.push({ name: 'default'});
        }

        var responsePrihod = await this.$http.get(this.$restApi + '/prihods/' + this.id);
        this.prihodModel = responsePrihod.data;

        var responseAlbum = await this.$http.get(this.$restApi + '/prihods/' + this.id + '/album/' + this.albumId);
        var prihodAlbumModel = responseAlbum.data;
        this.albumName = prihodAlbumModel.name;
        this.inError = false;
        } catch (error) {
        console.log(error);
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'editprihodalbums', params: { id: this.id}});
        }
        }
    },

    async deleteAlbum()
  {
  try {
        await this.$http.delete(this.$restApi + '/prihods/' + this.id + '/albums/' +  + this.albumId+ '/delete/');   
        this.$router.push({ name: 'editprihodalbums', params: { id: this.id} });
      } catch (error) {
        this.inError = true;
        console.log(error);
      }
  },
  cancelDelete()
  {
    this.$router.push({ name: 'editprihodalbums', params: { id: this.id} });
  },
 },

 created(){
  this.getData();
},
}
</script>

<style scoped>
.tablestyling{
border-color:#0066FF;
border-width:2px;
border-style:solid;
width:900px;
border-collapse:collapse;
margin-top: 20px;
}
</style>