<template>
<div>
    <div class="danger">Danger Zone</div>
    <table cellSpacing="4" align="center" border="0" style="padding-top:200px;">
    <tr>
        <td colspan="2" align="center"><img src="../../assets/ns_kettle.jpg" border="0"/></td>
    </tr>
    <tr>
    <td>
    <input type="text" class="textheader" v-model="text" v-on:change="onChangeText()" :class="{'vailderror': !isValidText}" :maxlength="maxLengthText"/>
    </td>
    <td>
        <button class="btn add_btn" v-on:click="generateHash()">Сгенерировать!</button>
    </td>
    </tr>
    </table>

    <table cellSpacing="4" align="center" border="0" style="padding-top:10px;">
    <tr>
    <td>
    <textarea class="onlytext" v-model="generated" readonly></textarea>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
const MaxLengthText = 20;
export default 
{
name:'HashComponent',

components: {
},

props:{
    maxLengthText:
   {
    type: Number,
    default: MaxLengthText
   },
},

data(){
    return {
        generated: String,  
        text: String,
        inError: Boolean,
        isFirstLoadWhenNew: Boolean,
    }
},

computed:{
    isValidText: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }
        return (this.text.length >= 1) && (this.text.length <= MaxLengthText);
    }
},

methods:{
    getData() {
        this.text = '';
        this.generated = '';
        this.isFirstLoadWhenNew = true;
        this.inError = false;
    },

     onChangeText(){
        this.isFirstLoadWhenNew = false;
        this.generated = '';
        this.inError = false;
    },

    async generateHash(){
        this.onChangeText();

        if (this.isValidText)
        {
        try {
            var response = await this.$http.post(this.$loginApi + '/hash/',
            {
                text: this.text
            });   
            this.generated = response.data;
        } 
        catch (error) {
            this.inError = true;
            console.log(error);
        }       
        }
    }
},

  created(){
  this.getData();
 },

   mounted(){
    this.$store.commit('formUpdate', {
            markupType: 2
        });
    }

}
</script>

<style scoped>
.textheader{font-size: 16px; width: 300px; height:45px;}

.add_btn{width:300px;}

.vailderror{border:2px solid red; background-color:#f1888841}

.danger{
    color:#af0606;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    padding-left:15px;
    padding-top:15px;
    font-weight: bold;
}

.onlytext{
    font-size: 14px;
    width: 590px;
    height: 60px;
    resize: none;
    }
</style>