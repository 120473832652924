<template>
    <div :style="style">
        <ckeditor v-model="contentEditor" :config="editorConfig"  @ready="onEditorReady"></ckeditor>
    </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default 
{
    name:'Wysiwyg',
    components:{
    ckeditor: CKEditor.component
    },

    props:{
    width: String,
    height: String,
    marginLeft: String,
    customClass: String,
    content: String
    },

     computed: {
      style () {
        return 'width:' + this.Width + 'px; height:' + this.Height + 'px; margin-left:' + this.MarginLeft + 'px;';
      }
    },
    data(){
        return { 
        contentEditor: this.content,
        editorConfig:{
            resize_enabled: false,
            height: 320,
            allowedContent: true,
            extraPlugins: 'colorbutton'
            },
        editor: CKEditor
        }
    },
    methods:{       
        insertAtCursor: function(strHtml)
        {
            this.editor.insertHtml(strHtml);
        },

        onEditorReady: function(editor)
        {
            this.editor = editor;
        }

    },

     mounted() {
    },
}
</script>

<style>
</style>