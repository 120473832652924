<template>
<div v-if="loaded">
    <h1>Раздел "Наши События"- Редактирование</h1>
    <h2 class="likebr">Шаг 3 из 3: Дата публикации и видимость события</h2>
    <span class="greentext" v-if="visibleFromDb == true">Событие выводится на сайт c {{publicDateFromDb}}</span>
    <span class="redtext" v-if="visibleFromDb == false">Событие пока не выводится на сайт</span>
    <div class="redtext" v-if="inError == true">{{this.errorMessage}}</div>
    
    <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
        <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
	</tr>

     <tr bgcolor="#ffffff">
       <td align="right" class="bluehead" width="200">ID:</td>
       <td align="left">
        <span v-if="id > 0">{{id}}</span> 
        </td> 
     </tr>
     
     <tr bgcolor="#ffffff">
        <td align="right" class="bluehead">ВКЛ:<font color="#ff0000">*</font></td>
		<td nobr>
        <select v-model="isVisible">
            <option value="false">НЕТ</option>
            <option value="true">ДА</option>
        </select>					
		</td>
    </tr>

    <tr bgcolor="#ffffff" >
      <td align="right" class="bluehead">Дата публикации:<font color="#ff0000">*</font></td>
      <td>
        <input type="text" :maxlength="maxLengthDate" value="" class="date" v-model="publicDate" :class="{'vailderror': !isValidPublicDate}">
        <input :maxlength="maxLengthTimes" class="time" v-model="publicHours" :class="{'vailderror': !isValidHours}"> ч. 
        <input :maxlength="maxLengthTimes" class="time" v-model="publicMinutes" :class="{'vailderror': !isValidMinutes}"> мин.
      </td>
    </tr>

    <tr bgcolor="#ffffff">
        <td colspan="2" align="center">
        <button class="btn" v-on:click="backward">Назад</button><button class="btn" v-on:click="forward">Сохранить</button>
        </td>
    </tr>
    </table>
</div>
</template>

<script>
const MaxLengthDate = 10;
const MaxLengthTimes = 2;
const DefaultErrorMessage = 'Не получилось отправить данные. Свяжитесь с разработчиком.';

export default 
{
    name:'NewsDate',
    props:
    {
        uid: String,
        maxLengthDate:
        {
            type: Number,
            default: MaxLengthDate
        },
        maxLengthTimes:
        {
            type: Number,
            default: MaxLengthTimes
        },
    },

    data()
    {
    return {
      isFirstLoadWhenNew: Boolean,
      inError: Boolean,
      loaded : Boolean,
      isVisible: String,
      visibleFromDb : Boolean,
      publicDate: String,
      publicDateFromDb: String,
      publicHours: String,
      publicMinutes: String,
      id: Number,
      errorMessage: String
     }
    },

    computed:
    {
        isValidPublicDate: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }

        var dtRegex = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/;
        return (this.publicDate.length <= MaxLengthDate) && (dtRegex.test(this.publicDate));
        },
       
        isValidHours: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }

        var dtRegex = /^[0-9]{2}$/;
        return (this.publicHours.length <= MaxLengthTimes) 
         && dtRegex.test(this.publicHours) && (this.publicHours >=0) && (this.publicHours <= 23);
        },

        isValidMinutes: function () {
        if (this.isFirstLoadWhenNew)
        {
            return true;
        }
  
        var dtRegex = /^[0-9]{2}$/;
        return (this.publicMinutes.length <= MaxLengthTimes)
         && dtRegex.test(this.publicMinutes) && (this.publicMinutes >=0) && (this.publicMinutes <= 23);
        }, 
    },


    methods:{
async getData() {
       try {
        var response = await this.$http.get(this.$restApi +'/news/' +  this.uid + '/dates');
        this.id = response.data.id;
        this.isVisible = response.data.visible.toString();
        this.visibleFromDb = response.data.visible;
        this.publicDate = response.data.publicDateRusString;
        this.publicDateFromDb = response.data.publicDateRusString;
        this.publicHours = response.data.publicHours;
        this.publicMinutes = response.data.publicMinutes; 
        this.isFirstLoadWhenNew = true;
        this.loaded = true;
        this.errorMessage = DefaultErrorMessage;
      } catch (error) 
      {
        console.log(error);
        
        if (error.response.status == '404')
        {
          this.$router.push({ name: 'eventslist'});
        }
      }
     },
async trySendFullText(){
   try{
        this.isFirstLoadWhenNew = false;
        var isValid = true;
        this.inError = false;
        this.publicDate = this.publicDate.trim();
        this.publicHours = this.publicHours.trim();
        this.publicMinutes = this.publicMinutes.trim();

        if (!this.isValidPublicDate || !this.isValidHours || !this.isValidMinutes)
        {
            isValid = false; 
        }
        if (isValid)
        {
          await this.$http.put(this.$restApi + '/news/' +  this.uid + '/dates', 
          {
            visible: this.isVisible == 'true' ? true: false,
            publicDateRusString: this.publicDate.trim(),
            publicHours: this.publicHours.trim(),
            publicMinutes: this.publicMinutes.trim(),
            uid: this.uid,
          });

          return true;
        }

      } catch (error) {
        this.inError = true;
        if (error.response !=null && error.response.data!= null)
        {
            this.errorMessage = error.response.data;
        }
        else
        {
            this.errorMessage = DefaultErrorMessage;
        }
        console.log(error);
        return false;
      }
    },

    async backward()
    {
    
       var res = await this.trySendFullText();
        if (res)
        {
          this.$router.push({ name: 'editeventfull', params: { id: this.uid } });
        }
    },

    async forward()
    {
        var res = await this.trySendFullText();
        if (res)
        {
           this.$router.push({ name: 'eventslist'});
        }
    },

    },

    created(){
    this.loaded = false;
    this.getData();
    },
}
</script>

<style scoped>
select {width: 60px; height: 30px; cursor: pointer;}
input {height: 30px}
input.date {width: 100px; margin-right: 15px; font-size: 14px;}
input.time {width: 50px; padding-right: 15px; font-size: 14px;}
</style>