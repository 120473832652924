<template>
<div v-if="loaded">
    <h1>Раздел "Наши События"- Редактирование</h1>
    <h2 class="likebr">Шаг 2 из 3: Фотографии и полный текст события</h2>
    <span class="greentext" v-if="visible == true">Событие выводится на сайт c {{publicDate}}</span>
    <span class="redtext" v-if="visible == false">Событие пока не выводится на сайт</span>
    <div class="redtext" v-if="inError == true">Не получилось отправить данные. Свяжитесь с разработчиком.</div>
    
    <table cellspacing="1" cellpadding="3" width="950px" border="0" class="child" style="padding-top:10px;">
    <tr bgcolor="#ffffff">
        <td colspan="2">Поля, помеченные&nbsp;<font color="#ff0000">*</font>,&nbsp;обязательны для заполнения.</td>
	</tr>
    
    <tr bgcolor="#ffffff">
    <td align="right" class="bluehead" width="150px">Картинки:<font color="#ff0000">*</font></td>
		<td nobr >
      <div class="addpicdiv" :class="{'vailderror': !isValidPictures}">
        <div class="bluehead">Добавление картинки:</div>
        <div class="redtext" v-if="inFileError == true">Не получилось добавить фото: {{fileErrorText}}</div>
        <div>
          <input type="file" ref="file" v-on:change="handleFileUpload()">
        </div>
        <div style="cursor:pointer; padding-top:5px">
          <input type="radio" name="whatToDo" id ="rgFirst" v-model="whatToDo" value="1">
          <label for="rgFirst">Создать картинку стандартного размера</label>
        <br>
        <input type="radio" name="whatToDo" id="rgSecond" v-model="whatToDo" value="2">
        <label for="rgSecond">Создать картинку стандартного и увеличенного размеров</label>
        </div>
        <div>
          <button style="cursor:pointer; margin:5px;" v-on:click="submitFile()">Добавить</button>
        </div>
      </div>

      <div class="fieldset" v-if="(pictures !== null) && (pictures.length>0)">
            <div v-for="pic in this.pictures" v-bind:key="pic.pictureId">
              <div class="divimages">
                <div>
                  <input type="radio" name="mainPicture" v-model="mainPicture" :value="pic.pictureId" class="inputimages">
                </div>
                <div>
                  <img alt="" style="margin-right:5px" width="100" height="100" :src="pic.smallUrl"/>
                </div>
                <div>
                  <input type="image" :src='require("@/assets/delete_small.png")' :value="pic.pictureId" class="inputimages" v-on:click="deletePicture(pic.pictureId)"/><br/>
                  <span class="bluehead" title="Вставить в текст" v-on:click="copyToEditor(makeInsertHtml(pic.pictureId, pic.standardWidth, pic.standardHeight, pic.standardUrl, pic.bigUrl))">
                    {{pic.standardWidth}}x{{pic.standardHeight}}
                    <input type="image" :src='require("@/assets/zoom_in_small.png")' class="inputimages" v-if="pic.bigWidth > 0"/>
                    <input type="image" :src='require("@/assets/zoom_in_blank.png")' class="inputimages" v-if="pic.bigWidth == 0"/> 
                  </span>
                </div>
              </div>
            </div>
      </div>		
		</td>
    </tr>

    <tr bgcolor="#ffffff" >
      <td align="right" class="bluehead">Полный текст:<font color="#ff0000">*</font></td>
      <td :class="{'vailderror': !isValidFull}">
        <Wysiwyg ref="fullEditor" width="760" :content="content" height="450" marginLeft="10"/>
      </td>
    </tr>

    <tr bgcolor="#ffffff">
    <td colspan="2" align="center">
    <button class="btn" v-on:click="backward">Назад</button><button class="btn" v-on:click="forward">Далее</button>
    </td>
    </tr>
    </table>
</div>
</template>

<script>
import { WhatToDoWithEventPhoto } from '../../settings/WhatToDoWithEventPhoto.ts'; 
import Wysiwyg from '../Wysiwyg.vue'
export default 
{
    name:'NewsFull',
    components:
    {
      Wysiwyg
    },
    props:
    {
    uid: String
    },
    data(){
    return {
        isFirstLoadWhenNew: Boolean, 
        inFileError: Boolean,
        fileErrorText: String,
        inError: Boolean,
        loaded : Boolean,
        visible: Boolean,
        publicDate: String,
        file: String,
        whatToDo: Number,
        pictures : [],
        mainPicture : Number,
        content : String,
        }
    },
computed:{
isValidFull: function () {
  if (this.isFirstLoadWhenNew)
  {
    return true;
  }

  if (this.content.length > 0)
  {
    return true;
  } 

  return false;
  },
  isValidPictures: function () {
    if (this.isFirstLoadWhenNew)
    {
      return true;
    }

      if (this.mainPicture > 0)
      {
        return true;
      } 

      return false;
  },
},

methods:{

  handleFileUpload(){
    this.inFileError = false;
    this.fileErrorText = '';
    this.file = this.$refs.file.files[0];
  },

  makeInsertHtml(id, width, height, url, bigUrl){
    if (bigUrl != '' && bigUrl != null)
    {
      return '<a class="sys_piclens" id="piclens' + id +'" href="'+ bigUrl
      +'" title="" txt=""><img align="left" style="margin-right:5px; margin-bottom:5px;" border="0" alt="" width="'
      + width +'"  height="' + height + '" src="'+ url +'"/></a>';                
    }
    
    return '<img alt="" align="left" style="margin-right:5px; margin-bottom:5px;" width="'
      + width + '"  height="'+ height + '"  src="'+ url + '"/>';
  },

  copyToEditor(strHtml){
    //this.$refs.fullEditor.contentEditor = strHtml + this.$refs.fullEditor.contentEditor;
    this.$refs.fullEditor.insertAtCursor(strHtml);
    return false;
  },

  async submitFile(){
    try{

      if (this.file == '')
      {     
        throw new Error('Файл не выбран!');
      }

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('whatToDo', this.whatToDo);
      var response = await this.$http.post(this.$restApi +'/news/' +  this.uid + '/photo',formData, {
        headers: {'Content-Type': 'multipart/form-data'}});
      
      if (response.status != 200)
      {
        this.inFileError = true;
        return;
      }

      this.inFileError = false;
      this.fileErrorText = '';
      await this.getPictures();
    }
    catch(error)
    {
      console.log(error);
     this.inFileError = true;
     if (typeof(error.response) == 'undefined')
     {
        this.fileErrorText = error.message;
     }
     else
     {
        this.fileErrorText = error.response.data;
     }
    console.log(error);
    }
  },

  async deletePicture(pictureId)
  {
  try {
        await this.$http.delete(this.$restApi + '/news/' +  this.uid + '/photo/'+ pictureId);
        await this.getPictures();
    
      } catch (error) {
        console.log(error);
      }
  },

  async getPictures() {
  try {
        var response = await this.$http.get(this.$restApi + '/news/' +  this.uid + '/photos');
        this.pictures = response.data.pictures;
        this.pictures.forEach(element => {
          if (element.isMain)
          {
            this.mainPicture = element.pictureId;
          }
        });
    
      } catch (error) {
        console.log(error);
      }
},

  async getData() {
       try {
        var response = await this.$http.get(this.$restApi +'/news/' +  this.uid + '/full');
        this.visible = response.data.visible;
        this.publicDate = response.data.publicDateRusString + ' ' + response.data.publicDateRusTime;
        this.content = response.data.fullText;

        await this.getPictures();
        this.file = '';
        this.loaded = true;
        this.inFileError = false;
        this.inError = false;
        this.whatToDo = WhatToDoWithEventPhoto.Standard;
      } catch (error) {
        console.log(error);
      }
    },

async trySendFullText(){
   try{
         this.isFirstLoadWhenNew = false;
        var isValid = true;
        this.inError = false;

        if (!this.isValidFull || !this.isValidPictures)
        {
            isValid = false; 
        }

        if (isValid)
        {
          var response = await this.$http.put(this.$restApi +'/news/' +  this.uid + '/full', 
          {
            fullText: this.content,
            mainPicture: this.mainPicture,
            uid: this.uid,
          });
          if (response.status != 200)
          {
              this.inError = true;
              return;
          }

          return true;
        }

      } catch (error) {
        this.inError = true;
        console.log(error);

        return false;
      }
    },

    async backward()
    {
      this.content = this.$refs.fullEditor.contentEditor;
       var res = await this.trySendFullText();
        if (res)
        {
          this.$router.push({ name: 'editeventanons', params: { id: this.uid } });
        }
    },

    async forward()
    {
        this.content = this.$refs.fullEditor.contentEditor;
        var res = await this.trySendFullText();
        if (res)
        {
          this.$router.push({ name: 'editeventdates', params: { id: this.uid } });
        }
    },
 },
    created(){
    this.loaded = false;
    this.getData();
    this.isFirstLoadWhenNew = true;    
    }
}
</script>

<style scoped>
div.redtext {padding-top: 5px; padding-bottom: 10px;}

.divimages{
  float:left;
  cursor: pointer;
  padding-bottom: 7px;
  padding-right: 7px;
}

.divimages div{
  float:left;
}

.inputimages{
  vertical-align: top;
  cursor: pointer;
  margin-right:7px;
  margin-bottom:5px;
}

.fieldset{
  margin-top: 10px;
  padding-top: 5px;
  border: 1px solid;
  border-bottom-color: #0066ff;
  border-top-color: #0066ff;
  border-right-color: #0066ff;
  border-left-color: #0066ff;
  height: 220px; 
  margin-left: 10px;
  overflow-y: scroll; 
  margin-right: 10px; 
  margin-bottom: 5px;
}

span.bluehead{
  text-decoration-line: underline;
}
</style>